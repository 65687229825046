<template>
  <div class="container">
    <h4 class="text-center my-4">Список студентов для привязки преподавателей</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <DataTable :value="students" showGridlines stripedRows
                 :globalFilterFields="['last_name','first_name', 'barcode']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="100"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,100,200]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск"/>
            </span>
          </div>
        </template>
        <template #empty>
          Не найдено
        </template>
        <template #loading>
          Идет поиск. Пожалуйста подождите.
        </template>

        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>

        <Column header="Баркод" field="barcode"></Column>

        <Column header="ФИО">
          <template #body="{data}">
            {{data.last_name}} {{data.first_name}}
          </template>
        </Column>

        <Column header="ОП">
          <template #body="{data}">
            <div class="fw-bold">{{data.education_speciality_name}}</div>
            <div>{{data.education_speciality_code}}</div>
            <div>{{data.language_name}}</div>
          </template>
        </Column>

        <Column header="Действие">
          <template #body="{data}">
            <div v-for="(d, dIndex) in data.disciplines" :key="dIndex" class="row rounded-3 border mb-2 p-2">
              <div class="col-md-5">
                {{d.education_discipline_name}}
              </div>
              <div class="col-md-4">
                <Dropdown v-model="d.pps_id" :options="pps" optionLabel="full_name" optionValue="id"
                          placeholder="Выберите преподавателя" class="w100p" :filter="true"/>
              </div>
              <div class="col-md-3">
                <Button icon="pi pi-save" label="Сохранить" class="p-button-rounded" :disabled="!d.pps_id"
                        @click="saveDifferenceRatings(d)"/>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>

    </div>


  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from 'primevue/api'


export default {
  name: 'DepartmentDifferenceStudents',
  data() {
    return {
      loading: true,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'last_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'first_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'barcode': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },
      students: []
    }
  },
  computed: {
    ...mapState('academicDebtService', ['academicDebtService']),
    pps() {
      return this.academicDebtService.pps.map(i => ({
        id: i.id,
        full_name: `${i.lastname} ${i.firstname} ${i.middlename}`
      }))
    }
  },
  methods: {
    ...mapActions('departmentDifference', ['GET_STUDENTS', 'PUT_DIFFERENCE_RATINGS_PPS']),
    ...mapActions('academicDebtService', ['GET_PPS']),
    async saveDifferenceRatings(differenceRatings) {

      const form = {
        id: differenceRatings.difference_ratings_id,
        pps_id: differenceRatings.pps_id
      }

      const res = await this.PUT_DIFFERENCE_RATINGS_PPS(form)
      if (res) {
        this.students = await this.GET_STUDENTS()
        this.$message({title: 'Успешно сохранено'})
      }
    }
  },
  async mounted() {
    this.students = await this.GET_STUDENTS()

    if (this.students.length) {
      await this.GET_PPS(1)
    }

    this.loading = false
  },
}

</script>